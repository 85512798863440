import React, {lazy, Suspense} from 'react';
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";

import MessageUs from './MessageUs';

import '../style.scss';
import 'react-toastify/dist/ReactToastify.css';
import {WIDGET_TYPES} from "../constants";
const Space = lazy(() => import('./Space'));

export default function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route path="/messageus/:clientId/frisbies/:frisbieId/widget"
                           render={({match}) => <MessageUs clientId={match.params.clientId}
                                                           frisbieId={match.params.frisbieId}
                                                           widgetType={WIDGET_TYPES.MESSENGERS}
                           />}/>
                    <Route path="/messageus/:clientId/frisbies/:frisbieId/feedbackform"
                           render={({match}) => <MessageUs clientId={match.params.clientId}
                                                           frisbieId={match.params.frisbieId}
                                                           widgetType={WIDGET_TYPES.FEEDBACK_FORM}
                           />}/>
                    <Route path="/messageus/:clientId/frisbies/:frisbieId/callback"
                           render={({match}) => <MessageUs clientId={match.params.clientId}
                                                           frisbieId={match.params.frisbieId}
                                                           widgetType={WIDGET_TYPES.CALLBACK}
                           />}/>
                    <Route path="/" component={Space}/>
                </Switch>
            </Suspense>
        </Router>
    )
}