import React, { Component } from 'react';
import Widget from "../components/Widget/Widget";
import PropTypes from "prop-types";
import messageUsService from "../services/MessageUsService";
import { WIDGET_TYPES } from "../constants";
import FeedbackForm from "../components/FeedbackForm/FeedbackForm";
import Callback from "../components/Callback/Callback";

class MessageUs extends Component {
    constructor(props) {
        super(props);

        this.state = { config: null };
    }

    componentDidMount() {
        const { clientId, frisbieId, widgetType } = this.props;
        messageUsService.getFrisbieWidgetConfig(clientId, frisbieId, widgetType).then(async (response) => {
            if (response && response.ok) {
                const config = await response.json();
                this.setState({ config });
            }
        });
    }

    renderWidget = () => {
        const { features, ...otherProps } = this.state.config;
        return (<Widget widget={otherProps} features={features} />);
    }

    renderFeedbackForm = () => (<FeedbackForm
        feedbackForm={this.state.config.feedbackForm}
        features={this.state.config.features} />);

    renderCallback = () => (<Callback callback={this.state.config.callback} features={this.state.config.features} />);

    render() {
        if (!this.state.config)
            return null;

        switch (this.props.widgetType) {
            case WIDGET_TYPES.MESSENGERS:
                return this.renderWidget();
            case WIDGET_TYPES.FEEDBACK_FORM:
                return this.renderFeedbackForm();
            case WIDGET_TYPES.CALLBACK:
                return this.renderCallback();
            default:
                return null;
        }
    }
}

MessageUs.propTypes = {
    clientId: PropTypes.string.isRequired,
    frisbieId: PropTypes.string.isRequired,
    widgetType: PropTypes.string.isRequired
};

export default MessageUs;
