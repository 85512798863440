const messengerButton = { width: 64, height: 72 };
const widget = { bottom: 10, padding: 24 };
const tooltip = { width: 103, padding: 24 };
const greeting = { width: 200, padding: 24, margin: 32 };
const timeoutOnUpdate = 500;

class WidgetConfig {
    getWidth(withTooltip = false) {
        return messengerButton.width + 2 * widget.padding + (withTooltip ? tooltip.width + tooltip.padding : 0);
    }

    getGreetingSize() {
        const width = greeting.margin + 2 * greeting.padding + greeting.width;
        const toggleHeight = messengerButton.height + 2 * widget.padding;
        const greetingElement = document.getElementsByClassName('Widget__greeting-msg');
        const greetingElementHeight = greetingElement && greetingElement.length > 0
            ? greetingElement[0].clientHeight : 250;

        const height = toggleHeight + greetingElementHeight;

        return {width: `${width}px`, height: `${height}px`};
    }

    getMessengersSize(isOpened = false, withTooltip = false) {
        const width = this.getWidth(withTooltip);
        const toggleHeight = messengerButton.height + 2 * widget.padding;
        const messengersElement = document.getElementsByClassName('Widget__messengersPanel');
        const height = isOpened && messengersElement && messengersElement.length > 0
            ? toggleHeight + messengersElement[0].clientHeight
            : toggleHeight + widget.bottom;

        return {width: `${width}px`, height: `${height}px`};
    }

    getTimeout() {
        return timeoutOnUpdate;
    }
}

const widgetConfig = new WidgetConfig();

export default widgetConfig;