export const WIDGET_TYPES = {
    MESSENGERS: 'widget',
    FEEDBACK_FORM: 'feedbackForm',
    CALLBACK: 'callback'
};

export const WIDGET_IFRAMES = {
    [WIDGET_TYPES.MESSENGERS]: 'frisbie_widget_iframe',
    [WIDGET_TYPES.FEEDBACK_FORM]: 'frisbie_feedback_form_iframe',
    [WIDGET_TYPES.CALLBACK]: 'frisbie_callback_iframe'
};

export const WIDGET_FORM_CONFIG = {
    AUTO_CLOSE_TIMEOUT: 1000,
    CALLBACK: {
        BUTTON: {
            WIDTH: '120px',
            HEIGHT: '120px',
            HORIZONTAL_INDENT: '70px',
            VERTICAL_INDENT: '15%'
        }
    }
};

export const MESSENGERS = {
    TELEGRAM: 'telegram',
    WHATSAPP: 'whatsapp',
    VIBER: 'viber',
    FACEBOOK: 'facebook',
    MESSENGER: 'facebook-messenger'
};

export const WIDGETS_LAYOUT = {
    [WIDGET_TYPES.FEEDBACK_FORM] : {
        MOBILE : {
            HORIZONTAL_INDENT: '24px',
            VERTICAL_INDENT: '28px'
        },
        DESKTOP: {
            HORIZONTAL_INDENT: '120px',
            VERTICAL_INDENT: 0
        }
    },
    [WIDGET_TYPES.CALLBACK] : {
        MOBILE: {
            HORIZONTAL_INDENT: 'calc(50% - 60px)',
            VERTICAL_INDENT: '0'
        },
        DESKTOP: {
            HORIZONTAL_INDENT: '70px',
            VERTICAL_INDENT: '15%'
        }
    }
};

export const PATH_REGEX = {
    CLIENT: /^\/clients\/[\w-]{36}\/?/i,
    ORDER: /^\/clients\/[\w-]{36}\/order\/?$/i,
    PAYMENTS: /^\/payments\/(success|fail)$/i
}

export const FRISBIE_EXPIRE_DAYS = 30;