import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Widget.scss';

class Toggle extends Component {
    constructor(props) {
        super(props);

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler() {
        this.toggle();
    }

    toggle() {
        const valueChanged = this.props.valueChanged;
        if (valueChanged) {
            valueChanged(!this.props.value);
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: this.props.config.color }} className={'Widget__button --animation'}
                onClick={this.onClickHandler}>
                {
                    !this.props.value
                        ? (<FontAwesomeIcon className="Widget__icon Icon Icon--white" icon={faComments} size="3x" />)
                        : (<>
                            <FontAwesomeIcon
                                className="Widget__icon Widget__icon--small Widget__iconClose Icon--white Icon-close"
                                icon={faTimes} size="3x" />
                            {this.props.showSignature && <a className="Sinature" href={window.reactAppSettings.siteUrl} target="_blank" rel="noopener noreferrer">Frisbie.me</a>}
                        </>)
                }
                { this.props.config.callToAction
                    ? <span className='Widget__buttonText'>{this.props.config.callToAction}</span>
                    : null
                }
            </div>
        )
    }
}

export default Toggle;