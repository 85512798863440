import authService from "./AuthorizeService";
import {StatusCodes} from "http-status-codes";

export default class BaseService {
    async fetchWithAuth(input, { headers, ...init } = {}) {
        const token = await authService.getAccessToken();
        const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` };
        const requestedWithHeader = { 'X-Requested-With': 'XMLHttpRequest' };
        return fetch(input, { ...init, headers: { ...headers, ...requestedWithHeader, ...authHeader } }).then(response => {
            if (response && !response.ok) {
                if (response.status === StatusCodes.UNAUTHORIZED) {
                    const location = response.headers && response.headers.get('location');
                    if (location) {
                        const locationUrl = new URL(response.headers.get('location'));
                        const loginUrl = `${locationUrl.origin}${locationUrl.pathname}`;
                        window.location.replace(loginUrl);
                    }
                }
            }

            return response;
        })
    }

    log(message, level = 'info') {
        if (process.env.NODE_ENV === 'development') {
            if (level === 'error') {
                console.error(message);
            } else {
                console.log(message);
            }
        }
    }
}