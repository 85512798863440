import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from "i18next-http-backend";

import translationEng from './assets/locales/en';
import translationRus from './assets/locales/ru';

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: translationEng
            },
            ru: {
                translations: translationRus
            }
        },
        lng: 'ru',
        fallbackLng: 'ru',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            useSuspense: true
        }
    });

export default i18n;
