import BaseService from "./BaseService";

const getFrisbieUrl = (clientId, frisbieId) =>
    `${window.reactAppSettings.apiUrl}/messageus/${clientId}/frisbies/${frisbieId}`;

class MessageUsService extends BaseService {
    async getFrisbieWidgetConfig(clientId, frisbieId, widgetType) {
        try {
            const frisbieUrl = getFrisbieUrl(clientId, frisbieId);
            const response = await fetch(`${frisbieUrl}/${widgetType}`);
            return response;
        } catch (err) {
            this.log(err, 'error');
        }
    }
}

const messageUsService = new MessageUsService();

export default messageUsService;