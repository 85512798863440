const Targets = {
    MESSENGERS: 'frisbie_widget_iframe',
    FEEDBACK_FORM: 'frisbie_feedback_form_iframe',
    CALLBACK: 'frisbie_callback_iframe'
};

const FRISBIE_ID = 'FRISBIE';

class Notifier {
    notifyMessengers(message) {
        this.notify(Targets.MESSENGERS, message)
    }

    notifyFeedbackForm(message) {
        this.notify(Targets.FEEDBACK_FORM, message);
    }

    notifyCallback(message) {
        this.notify(Targets.CALLBACK, message);
    }

    notify(target, message) {
        if (window.parent) {
            window.parent.postMessage({
                id: FRISBIE_ID,
                message: JSON.stringify({target: target, ...message})
            }, '*');
        }
    }
}
const notifier = new Notifier();

export default notifier;