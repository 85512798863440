import React from 'react';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import './FeedbackForm.scss';
import PropTypes from "prop-types";
import notifier from "../../notifier";
import notificationService from "../../services/NotificationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WIDGET_FORM_CONFIG, WIDGET_IFRAMES, WIDGET_TYPES, WIDGETS_LAYOUT } from "../../constants";
import { isMobile } from 'mobile-device-detect';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import * as Yup from "yup";

const DEFAULT_BUTTON_WIDTH = 450;
const DEFAULT_BUTTON_HEIGHT = 48;

class FeedbackForm extends React.Component {
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();

        this.buttonConfig = {
            position: 'left'
        };

        this.state = {
            openForm: false,
            feedbackSent: false,
            isTransition: false
        };

        this.handleIframeMessage = this.handleIframeMessage.bind(this);
    }

    componentDidMount() {
        this.onLayoutChanged(this.state.openForm);
        if (!this.props.isPreviewMode) {
            window.addEventListener('message', this.handleIframeMessage);
        }
    }

    componentWillUnmount() {
        if (!this.props.isPreviewMode) {
            window.removeEventListener('message', this.handleIframeMessage);
        }
    }

    handleIframeMessage(event) {
        event && event.data === WIDGET_IFRAMES[WIDGET_TYPES.FEEDBACK_FORM] && this.toggleForm(true);
    }

    onLayoutChanged(isOpen) {
        if (this.props.isPreviewMode)
            return;

        if (this.buttonRef && this.buttonRef.current) {
            this.buttonConfig = {
                ...this.buttonConfig,
                width: this.buttonRef.current.clientWidth,
                height: this.buttonRef.current.clientHeight
            }
        }
        const layout = isMobile
            ? WIDGETS_LAYOUT[WIDGET_TYPES.FEEDBACK_FORM].MOBILE
            : WIDGETS_LAYOUT[WIDGET_TYPES.FEEDBACK_FORM].DESKTOP;

        const style = isOpen
            ? { width: `100%`, height: `100%`, [`${this.buttonConfig.position}`]: 0, bottom: 0 }
            : {
                width: `${this.buttonConfig.width || DEFAULT_BUTTON_WIDTH}px`,
                height: `${this.buttonConfig.height || DEFAULT_BUTTON_HEIGHT}px`,
                [`${this.buttonConfig.position}`]: layout.HORIZONTAL_INDENT,
                bottom: layout.VERTICAL_INDENT
            };

        notifier.notifyFeedbackForm({ style });
    }

    toggleForm = (open) => {
        this.setState({ isTransition: true });
        this.onLayoutChanged(open);
        setTimeout(() => this.setState({ openForm: open, feedbackSent: false, isTransition: false }),
            100);
    };

    onSubmit = (feedback, setSubmitting) => {
        setSubmitting(true);
        notificationService.postWidgetNotification(this.props.feedbackForm.id, feedback, WIDGET_TYPES.FEEDBACK_FORM).then(() => {
            setSubmitting(false);
            this.setState({ feedbackSent: true });

            setTimeout(() => {
                this.toggleForm(false);
            }, WIDGET_FORM_CONFIG.AUTO_CLOSE_TIMEOUT);

        });
        notifier.notifyFeedbackForm({ action: 'submit' });
    };

    renderForm = () => {
        const { feedbackForm, t } = this.props;
        const initialValues = {
            name: '',
            email: '',
            phone: '',
            comments: '',
            privacyPolicy: false
        };
        return (
            <>
                <div className="FeedbackForm open">
                    <Formik validateOnBlur={false} validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(t('[FeedbackForm]NameIsRequired')),
                        email: Yup.string().email(t('[FeedbackForm]EmailIsNotValid'))
                            .when(['phone'], {
                                is: (phone) => !phone || !phone.trim(),
                                then: Yup.string().required(t('[FeedbackForm]EmailOrPhoneIsRequired'))
                            }),
                        privacyPolicy: Yup.bool().oneOf([true], t('[FeedbackForm]PrivacyPolicyIsRequired'))
                    })}
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => this.onSubmit(values, setSubmitting)}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FontAwesomeIcon className='FeedbackForm__close' icon={faTimesCircle}
                                    onClick={() => this.toggleForm(false)} />

                                <section>
                                    <h3 className="FeedbackForm__title"> {feedbackForm.title}</h3>
                                    <div className="mb-16 FeedbackForm__text">
                                        <p>{feedbackForm.text}</p>
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="text" name="name" placeholder={t('[FeedbackForm]Name')} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="email" name="email" placeholder={t('[FeedbackForm]Email')} />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="tel" name="phone" placeholder={t('[FeedbackForm]Phone')} />
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field component="textarea" name="comments"
                                            placeholder={t('[FeedbackForm]Comments')} />
                                    </div>
                                    <div className='small mb-16 relative'>
                                        <p>{feedbackForm.note}</p>
                                    </div>
                                </section>
                                <footer className="FeedbackForm__footer">
                                    {this.renderPrivacyPolicy()}
                                    <button type="submit" disabled={isSubmitting}
                                        className={'Button Button--bold mr-16'}
                                        style={{ backgroundColor: feedbackForm.color }}>
                                        {t('[FeedbackForm]Send')}
                                    </button>
                                    {
                                        !this.props.features?.noSignature &&
                                        <div className="Signature mt-16 flex h-center">
                                            <p>{this.props.t('ProvidedBy')}&nbsp;
                                            <a href={window.reactAppSettings.siteUrl} target="_blank" rel="noopener noreferrer">Frisbie.me</a>
                                            </p>
                                        </div>
                                    }
                                </footer>
                                {isSubmitting && <CircularProgress size={64} style={{ color: feedbackForm.color }} className="Progress" />}
                                {this.state.feedbackSent && <FontAwesomeIcon className='Progress Progress-success'
                                    style={{ color: feedbackForm.color }}
                                    icon={faCheckCircle} />}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div onClick={() => this.toggleForm(false)} className='darkBgModal'></div>
            </>
        )
    };

    renderPrivacyPolicy = () => {
        const { feedbackForm, t } = this.props;
        const privacyPolicyUrl = feedbackForm.privacyPolicy || window.reactAppSettings.privacyPolicy;
        return (<div className="Form-fieldset">
            <Field type="checkbox" name="privacyPolicy" />
            <label className="small" htmlFor="privacyPolicy">{t('[FeedbackForm]IAgree')}&nbsp;
                <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                    {t('[FeedbackForm]PrivacyPolicy')}
                </a>
            </label>
            <ErrorMessage name="privacyPolicy" component="div" className="invalid-feedback" />
        </div>);
    };

    renderButton = () => {
        const { feedbackForm, isPreviewMode } = this.props;
        this.buttonConfig.position = isMobile || feedbackForm.position.manual || feedbackForm.position.value.endsWith('l')
            ? 'left' : 'right';

        return (
            <div ref={this.buttonRef}
                className={`FeedbackForm__trigger ${isMobile ? 'mobile' : 'desktop'} [ flex flex-end ] 
                 ${this.buttonConfig.position} ${isPreviewMode ? 'preview' : ''}`}
                style={{ backgroundColor: feedbackForm.color }}
                onClick={() => this.toggleForm(true)}>
                {
                    isMobile ? (<FontAwesomeIcon className="FeedbackForm-icon" icon={faEnvelope} size="3x" />)
                        : (<h4 className="FeedbackForm__triggerTitle">{feedbackForm.welcomeMessage}</h4>)
                }
            </div>
        )
    };

    render() {
        if (this.state.isTransition)
            return null;

        return this.state.openForm ? this.renderForm() : this.renderButton();
    }
}

FeedbackForm.propTypes = {
    feedbackForm: PropTypes.object.isRequired,
    features: PropTypes.object,
    isPreviewMode: PropTypes.bool
};

export default withTranslation()(FeedbackForm);
