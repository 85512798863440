import BaseService from "./BaseService";

class NotificationService extends BaseService {
    notificationUrl = `${window.reactAppSettings.apiUrl}/notifications`;
    
    async postWidgetNotification(id, notification, widgetType) {
        try {
            const response = await fetch(`${this.notificationUrl}/${id}/${widgetType}`, {
                method: 'POST',
                body: JSON.stringify(notification),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response;
        }
        catch(err) {
            this.log(err, 'error');
        }
    }
}

const notificationService = new NotificationService();

export default notificationService;