import React from 'react';
import PropTypes from 'prop-types';

import './Widget.scss';
import MessengerIcon from "../shared/MessengerIcon/MessengerIcon";
import { MESSENGERS } from "../../constants";
import notifier from "../../notifier";

const validSchemes = ['https://', 'http://', 'viber://', 'skype:', 'weixin://', 'line://', 'mailto:', 'tel:'];

const openLink = (link) => {
    notifier.notifyMessengers({ action: 'submit' });
    window.open(link);
};

const isMessengerUrl = (url) => {
    if (!url) {
        return false;
    }

    let isValid = false;
    for (let i = 0; i < validSchemes.length; i++) {
        if (url.startsWith(validSchemes[i])) {
            isValid = true;
            break;
        }
    }

    return isValid;
};

const getLink = (messenger) => {
    let value = (messenger.value || '').trim().toLowerCase();
    if (isMessengerUrl(value)) {
        return value;
    }

    if (value) {
        switch (messenger.type.id) {
            case MESSENGERS.TELEGRAM:
                if (value.startsWith('@')) {
                    value = value.substring(1);
                } else if (!Number.isNaN(+value[0])) {
                    value = `+${value}`;
                }
                break;
            case MESSENGERS.WHATSAPP:
                if (value.startsWith('+')) {
                    value = value.substring(1);
                }
                break;
            case MESSENGERS.VIBER:
                if (!value.startsWith('+'))
                    value = `+${value}`;

                value = encodeURIComponent(value);
                break;
            case MESSENGERS.FACEBOOK:
            case MESSENGERS.MESSENGER:
                if(value.startsWith('@')) {
                    value = value.substring(1);
                }
                break;
            default:
                break;
        }
    }

    // eslint-disable-next-line no-template-curly-in-string
    return messenger.type.template.replace("${value}", value);
};


export default function Messenger({ messenger, hideTooltips }) {
    const messengerType = messenger.type;
    const link = getLink(messenger);

    return (
        <div className={`Widget__button --square --animaion ${messengerType.id}`}>
            <MessengerIcon className="Widget__icon Icon Icon--white" type={messengerType.id} size="3x" onClick={() => openLink(link)} />
            {!hideTooltips && (<span className='Widget__buttonText'>{messengerType.title}</span>)}
        </div>
    );
}

Messenger.propTypes = {
    messenger: PropTypes.object.isRequired,
    hideTooltips: PropTypes.bool
};


