import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt, faUnlink } from '@fortawesome/free-solid-svg-icons';
import './assets/styles/libs/_bootstrap.scss';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
library.add(fab);
library.add(faEnvelope);
library.add(faPhoneAlt, faUnlink);

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<I18nextProvider i18n={ i18n }> <App /> </I18nextProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();