import Toggle from './Toggle';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isMobile } from 'mobile-device-detect';

import './Widget.scss';
import Messenger from "./Messenger";
import notifier from "../../notifier";
import widgetConfig from "../../widgetConfig";
import { getMessengersComparer } from "../../helpers";
import { WIDGET_IFRAMES, WIDGET_TYPES } from "../../constants";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShakeHardAnimation = 'shake-hard shake-constant';

class Widget extends Component {
    constructor(props) {
        super(props);

        const showGreeting = !!this.props.widget.config.greetingMessage;
        if (showGreeting) {
            this.state = { isOpen: false, showGreeting: true, widgetShakeAnimation: '' };
            this.sizeState = { isOpen: false, withTooltip: false, isGreeting: true };
        }
        else {
            this.setSizeState({ isGreeting: false, isOpen: this.props.widget.config.isOpened });
            this.state = { showGreeting: false, isOpen: this.props.widget.config.isOpened };
        }

        this.sortMessengers = getMessengersComparer(true);
        this.handleIframeMessage = this.handleIframeMessage.bind(this);
    }

    setSizeState(newState) {
        if (this.props.isPreviewMode) {
            return;
        }

        this.sizeState = { ...this.sizeState, ...newState };

        let message = {};
        if (this.sizeState.isGreeting) {
            message.style = widgetConfig.getGreetingSize();
        } else {
            message.style = widgetConfig.getMessengersSize(this.sizeState.isOpen, this.sizeState.withTooltip);
            if ((newState.isOpen != null && !newState.isOpen) || (newState.withTooltip != null && !newState.withTooltip)) {
                message.timeout = widgetConfig.getTimeout();
            }
        }

        notifier.notifyMessengers(message);
    }

    componentDidMount() {
        if (this.props.isPreviewMode) {
            this.toggleValueChangedHandler(true);
        }
        else {
            window.addEventListener('message', this.handleIframeMessage);
        }

        this.setSizeState(this.sizeState);

        if (this.props.widget.config.isAnimationEnabled) {
            this.animationTimeout = setTimeout(() => {
                this.runAnimation();
                this.animationInterval = setInterval(() => {
                    this.runAnimation();
                }, this.props.widget.config.animationInterval * 1000);
            }, this.props.widget.config.animationTimeout * 1000);
        }
    }

    componentWillUnmount() {
        if (!this.props.isPreviewMode) {
            window.removeEventListener('message', this.handleIframeMessage);
        }

        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
            this.animationTimeout = null;
        }

        if (this.animationInterval) {
            clearInterval(this.animationInterval);
            this.animationInterval = null;
        }
    }

    handleIframeMessage(event) {
        event && event.data === WIDGET_IFRAMES[WIDGET_TYPES.MESSENGERS] && this.toggleValueChangedHandler(true);
    }

    runAnimation() {
        this.setState({ widgetShakeAnimation: ShakeHardAnimation });
        const animationTime = 1000; // 1 sec
        let timeout = setTimeout(() => {
            this.setState({ widgetShakeAnimation: '' });
            clearTimeout(timeout);
        }, animationTime);
    }

    toggleValueChangedHandler(value) {
        if (this.props.isPreviewMode) {
            this.setState({ showGreeting: !value });
        }
        else {
            this.stopGreeting();
        }
        this.setState({ isOpen: value });
        this.setSizeState({ isOpen: value });
    }

    renderMessengers(messengers, hideTooltips) {
        return (
            <div className={this.state.isOpen ? 'Widget__messengersPanel --open' : 'Widget__messengersPanel --close'}>
                {
                    messengers.sort(this.sortMessengers)
                        .map(messenger => (<Messenger key={messenger.id} messenger={messenger} hideTooltips={hideTooltips} />))
                }
            </div>
        )
    }

    stopGreeting() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        if (this.state.showGreeting) {
            this.setSizeState({ isGreeting: false, isOpen: this.props.widget.config.isOpened });
            this.setState({ showGreeting: false, isOpen: this.props.widget.config.isOpened });
        }
    }

    renderGreeting() {
        if (!this.props.widget.config.greetingMessage || this.state.isOpen) {
            return null;
        }

        this.setSizeState({ isGreeting: true });
        if (!this.props.isPreviewMode) {
            this.timer = setTimeout(() => {
                this.stopGreeting();
            }, this.props.widget.config.greetingTimeout * 1000);
        }

        const position = !isMobile && (this.props.widget.config.position.manual || this.props.widget.config.position.value.endsWith('l'))
            ? 'left' : 'right';
        return (
            <div className={`Widget__greeting-msg ${position}`}>
                <button className="Button-close flex v-center h-center" onClick={() => this.stopGreeting()}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <p>{this.props.widget.config.greetingMessage}</p>
            </div>
        )
    }

    render() {
        const messengers = this.props.widget.messengers;
        const hideTooltips = this.props.widget.config.hideTooltips;
        if (!this.props.isPreviewMode && (messengers == null || messengers.length === 0)) {
            return null;
        }

        const position = !isMobile && (this.props.widget.config.position.manual || this.props.widget.config.position.value.endsWith('l'))
            ? 'left' : 'right';
        return (
            <section
                className={`Widget ${this.state.widgetShakeAnimation}  ${this.props.widget.config.type} ${position}`}
                onMouseEnter={() => !hideTooltips && this.setSizeState({ withTooltip: true })}
                onMouseLeave={() => !hideTooltips && this.setSizeState({ withTooltip: false })}>
                {this.state.showGreeting ? this.renderGreeting() : null}
                {this.renderMessengers(messengers, hideTooltips)}
                <Toggle config={this.props.widget.config} value={this.state.isOpen} showSignature={!this.props.features?.noSignature}
                    valueChanged={(value) => this.toggleValueChangedHandler(value)} />
            </section>
        );
    }
}

Widget.propTypes = {
    widget: PropTypes.object.isRequired,
    features: PropTypes.object,
    isPreviewMode: PropTypes.bool
};

export default Widget;

