const defaultWidgetConfig = {
    greetingMessage: '', greetingTimeout: 5, callToAction: 'Message us',
    position: {manual: false, value: 'bl'}, isOpened: false, color: '#0075af',
    isAnimationEnabled: false, animationTimeout: 15, animationInterval: 15
};

const defaultFeedbackFormConfig = {
    greetingMessage: '', greetingTimeout: 5, position: {manual: false, value: 'br'}, color: '#f91b24',
    isEnabled: true, welcomeMessage: 'Message us', title: 'Feedback',
    text: 'Please leave your name and contact details, and we will reply in the next 24 hours',
    note: 'We do not send advertising messages or share contact information with third parties',
    email: '', telegram: '', yandexMetrika: '', googleAnalytics: '',
    useOnMobile: true
};

const defaultCallbackConfig = {
    isEnabled: true, title: 'Call back', text: 'Please leave your name and phone number and we will call you back',
    note: 'We do not send advertising messages or share contact information with third parties',
    position: {manual: false, value: 'tr'}, color: '#00c300', email: '', telegram: '',
    yandexMetrika: '', googleAnalytics: '',
    useOnMobile: true
}

const getLocalized = (obj, translate) => {
    const localizedObject = {};
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        localizedObject[key] = typeof value === 'string' ? translate(value) : value;
    });

    return localizedObject;
};

export const initFrisbieProperties = (frisbie, translate = (a) => a) => {
    const widget = frisbie.widget || {};
    const result = { ...frisbie,
        isEnabled: frisbie.isEnabled == null || frisbie.isEnabled,
        widget: { 
        ...widget, 
            config: widget.config || getLocalized(defaultWidgetConfig, translate),
            isEnabled: widget.isEnabled == null || widget.isEnabled
        },
        feedbackForm: frisbie.feedbackForm || getLocalized(defaultFeedbackFormConfig, translate),
        callback: frisbie.callback || getLocalized(defaultCallbackConfig, translate)
    };
    
    result.feedbackForm.privacyPolicy = result.feedbackForm.privacyPolicy || window.reactAppSettings.privacyPolicy;
    result.callback.privacyPolicy = result.callback.privacyPolicy || window.reactAppSettings.privacyPolicy;
    return result;
};

const messengersOrder = {
    email: 1,
    phone: 2,
    whatsapp: 3,
    telegram: 4,
    viber: 5,
    vk: 6,
    facebook: 7,
    skype: 8,
    instagram: 9,
    weixin: 10,
    line: 11
};

export const getMessengersComparer = (desc) => (a, b) => {
    const aOrder = messengersOrder[a.type.id];
    const bOrder = messengersOrder[b.type.id];
    let result = 0;
    if(Number.isInteger(aOrder) && Number.isInteger(bOrder)) {
        result = aOrder - bOrder;
    }
    else if(Number.isInteger(aOrder)) {
        result = -1;
    }
    else {
        result = 1;
    }

    return desc ? -1 * result : result;
};