import React from 'react';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import './Callback.scss';

import { isMobile } from 'mobile-device-detect';
import call from '../../assets/images/icons/call.svg';
import PropTypes from "prop-types";
import notifier from "../../notifier";
import { WIDGET_FORM_CONFIG, WIDGET_IFRAMES, WIDGET_TYPES, WIDGETS_LAYOUT } from "../../constants";
import notificationService from "../../services/NotificationService";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";

class Callback extends React.Component {
    constructor(props) {
        super(props);

        this.buttonConfig = {
            horizontal: isMobile ? 'left' : 'right',
            vertical: isMobile ? 'bottom' : 'top'
        };

        this.state = {
            openForm: false,
            callbackSent: false,
            isClosing: false
        };

        this.handleIframeMessage = this.handleIframeMessage.bind(this);
    }

    componentDidMount() {
        this.onLayoutChanged(this.state.openForm);
        if (!this.props.isPreviewMode) {
            window.addEventListener('message', this.handleIframeMessage);
        }
    }

    componentWillUnmount() {
        if (!this.props.isPreviewMode) {
            window.removeEventListener('message', this.handleIframeMessage);
        }
    }

    handleIframeMessage(event) {
        event && event.data === WIDGET_IFRAMES[WIDGET_TYPES.CALLBACK] && this.toggleForm(true);
    }

    onLayoutChanged(isOpen) {
        const layout = isMobile
            ? WIDGETS_LAYOUT[WIDGET_TYPES.CALLBACK].MOBILE
            : WIDGETS_LAYOUT[WIDGET_TYPES.CALLBACK].DESKTOP;

        const style = isOpen
            ? {
                width: `100%`,
                height: `100%`,
                [`${this.buttonConfig.horizontal}`]: 0,
                [`${this.buttonConfig.vertical}`]: 0,
                "border-radius": 0
            }
            : {
                width: WIDGET_FORM_CONFIG.CALLBACK.BUTTON.WIDTH,
                height: WIDGET_FORM_CONFIG.CALLBACK.BUTTON.HEIGHT,
                [`${this.buttonConfig.horizontal}`]: layout.HORIZONTAL_INDENT,
                [`${this.buttonConfig.vertical}`]: layout.VERTICAL_INDENT,
                "border-radius": '100%'
            };
        notifier.notifyCallback({ style });
    }

    toggleForm = (isOpen) => {
        this.setState({ isClosing: true });
        this.onLayoutChanged(isOpen);
        setTimeout(() => {
            this.setState({ openForm: isOpen, callbackSent: false, isClosing: false });
        }, 100);
    };

    onSubmit = (callback, setSubmitting) => {
        setSubmitting(true);
        notificationService.postWidgetNotification(this.props.callback.id, callback, WIDGET_TYPES.CALLBACK).then(() => {
            setSubmitting(false);
            this.setState({ callbackSent: true });

            setTimeout(() => {
                this.toggleForm(false);
            }, WIDGET_FORM_CONFIG.AUTO_CLOSE_TIMEOUT);
        });
        notifier.notifyCallback({ action: 'submit' });
    };

    renderForm = () => {
        let { t, callback } = this.props;
        const initialValues = {
            name: '',
            phone: '',
            comments: '',
            privacyPolicy: false
        };
        return (
            <>
                <div className='CallWidget'>
                    <Formik validateOnBlur={false} validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(t('[Callback]NameIsRequired')),
                        phone: Yup.string().required(t('[Callback]PhoneIsRequired')),
                        privacyPolicy: Yup.bool().oneOf([true], t('[Callback]PrivacyPolicyIsRequired'))
                    })}
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => this.onSubmit(values, setSubmitting)}>
                        {({ isSubmitting }) => (
                            <Form>
                                <FontAwesomeIcon className='CallWidget__close' icon={faTimesCircle}
                                    onClick={() => this.toggleForm(false)} />
                                <section>
                                    <h3 className="CallWidget__title">{callback.title}</h3>
                                    <div className="mb-16 CallWidget__text small">
                                        <p>{callback.text}</p>
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="text" name="name" placeholder={t('[Callback]Name')} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="tel" name="phone" placeholder={t('[Callback]Phone')} />
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="Form-fieldset">
                                        <Field type="text" component="textarea" name="comments"
                                            placeholder={t('[Callback]Comments')} />
                                    </div>
                                </section>
                                <footer className="CallWidget__footer">
                                    {this.renderPrivacyPolicy()}
                                    <button type="submit" disabled={isSubmitting}
                                        className={'Button Button Button--bold mr-16'}
                                        style={{ backgroundColor: callback.color }}>
                                        {t('[Callback]Send')}
                                    </button>
                                    {
                                        !this.props.features?.noSignature &&
                                        <div className="Signature mt-16 flex h-center">
                                            <p>{this.props.t('ProvidedBy')}&nbsp;
                                            <a href={window.reactAppSettings.siteUrl} target="_blank" rel="noopener noreferrer">Frisbie.me</a>
                                            </p>
                                        </div>
                                    }
                                </footer>
                                {isSubmitting && <CircularProgress size={64} style={{ color: callback.color }} className="Progress" />}
                                {this.state.callbackSent && <FontAwesomeIcon className='Progress Progress-success'
                                    style={{ color: callback.color }}
                                    icon={faCheckCircle} />}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div onClick={() => this.toggleForm(false)} className="darkBgModal" />
            </>
        );
    };

    renderPrivacyPolicy = () => {
        const { callback, t } = this.props;
        const privacyPolicyUrl = callback.privacyPolicy || window.reactAppSettings.privacyPolicy;
        return (<div className="Form-fieldset">
            <Field type="checkbox" name="privacyPolicy" />
            <label className="small" htmlFor="privacyPolicy">{t('[Callback]IAgree')}&nbsp;
                <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                    {t('[Callback]PrivacyPolicy')}
                </a>
            </label>
            <ErrorMessage name="privacyPolicy" component="div" className="invalid-feedback" />
        </div>);
    };

    renderButton() {
        let { isPreviewMode, callback } = this.props;
        this.buttonConfig.horizontal = isMobile || callback.position.manual || callback.position.value.endsWith('l')
            ? 'left' : 'right';
        this.buttonConfig.vertical = isMobile || callback.position.manual || callback.position.value.startsWith('b')
            ? 'bottom' : 'top';

        return (
            <div className={`openCallForm openCallForm${isPreviewMode ? '-preview' : ''} ${this.buttonConfig.horizontal} ${this.buttonConfig.vertical}`}
                onClick={() => this.toggleForm(true)}
                style={{ backgroundColor: callback.color }}>
                <img src={call} alt="call" />
            </div>
        );
    };

    render() {
        if (this.state.isClosing)
            return null;

        return this.state.openForm ? this.renderForm() : this.renderButton();
    }
}

Callback.propTypes = {
    callback: PropTypes.object.isRequired,
    features: PropTypes.object,
    isPreviewMode: PropTypes.bool
};

export default withTranslation()(Callback);