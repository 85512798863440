import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {findIconDefinition} from "@fortawesome/fontawesome-svg-core";

import './MessengerIcon.scss';

const getIconLookup = (type) => {
    if (type === 'email') {
        return {prefix: 'far', iconName: 'envelope'};
    }
    if (type === 'phone') {
        return {prefix: 'fas', iconName: 'phone-alt'};
    }

    return {prefix: 'fab', iconName: type};
};

export default function MessengerIcon({ type, ...props}) {
    const icon = findIconDefinition(getIconLookup(type));
    return (<FontAwesomeIcon icon={icon} {...props} data-messenger-type={type}/>);
}

